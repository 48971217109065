import React from 'react';
import '../../styles/components/Services.css';
import Lottie from "lottie-react";
import ServicesAnimation from '../../animations/web-design.json';


function Services() {
  return (

    <div id='servicios' className="contenedorPrincipal">
      <div className='contuno'>
        <div className="contenedorImagen">
          <div className="animacion">
            <Lottie
              animationData={ServicesAnimation}
              loop={true}
            />
          </div>

        </div>
        <div className='contenedorTexto'>
          <h1>Nuestros Servicios</h1>
          <p className='estiloTexto'>Ofrecemos soluciones creativas y profesionales para ayudar a tu empresa a destacar en un mundo competitivo. Desde diseño y desarrollo web hasta software personalizado, trabajamos estrechamente contigo para entender tus necesidades y ofrecerte soluciones a medida que impulsen tu crecimiento. Nuestro enfoque se basa en la innovación, la calidad y la satisfacción del cliente.</p>
        </div>
      </div>
      <div className='contenedorSecundario' >

      <div className='caja'>
          <div className='servicios'>
            <p className='bi bi-filetype-html  icono'></p>
            <p className='TituloServicio'> Desarrollo web </p>
            <br></br>
            <p className='TextoServicio'>Diseño web innovador y profesional para potenciar tu presencia en línea. Soluciones únicas y creativas que destacarán tu marca en un mundo digital competitivo</p>
          </div>
        </div>
        
        <div className='caja'>
          <div className='servicios'>
            <p className='bi bi-building-check icono'></p>
            <p className='TituloServicio'> Empresas </p>
            <br></br>
            <p className='TextoServicio'>Soluciones de software innovadoras y profesionales para impulsar el crecimiento de tu empresa. Desarrollo único y creativo que se adapta a tus necesidades. </p>
          </div>
        </div>

        <div className='caja'>
          <div className='servicios'>
            <p className=' bi bi-palette icono'></p>
            <p className='TituloServicio'> Diseño</p>
            <br></br>
            <p className='TextoServicio'>Diseños únicos y profesionales para destacar tu marca en un mundo competitivo. Creatividad y profesionalismo en cada detalle.</p>
          </div>
        </div>

        <div className='caja'>
          <div className='servicios'>
            <p className='bi bi-database-check  icono'></p>
            <p className='TituloServicio'> Hosting </p>
            <br></br>
            <p className='TextoServicio'>Nosotros alojaremos tu página web con un gran rendimiento. Con una disponibilidad 24/7 los 365 días del año.</p>
          </div>
        </div>

      </div>
    </div>



  );
}

export default Services;