import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import imagen from '../../images/web-developer_1920x1080.jpg';
import imagenDOS from '../../images/web-design_1920x1080.jpg';
import imagenTRES from '../../images/computer_1920x1080.jpg';
import '../../styles/components/Slider.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",right: 12,zIndex:1 }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left: 12, zIndex:1}}
        onClick={onClick}
      />
    );
  }

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow /> , 
      appendDots: dots => (
        <div
        style={{
          top: "auto",
          bottom: "10px", // Ajusta la posición vertical de los dots
          margin: "0 auto",
          zIndex: 1, // Agrega un índice z para asegurarse de que los dots estén por debajo de las imágenes
        }}
      >
        <ul className="slider-dots" style={{ margin: "10px", paddingLeft: 0 }}>{dots}</ul>
        </div>
      ),
    
    };

    return (
      <div id="inicio" className="contenedor">
        
        <Slider {...settings}>
            
          <div className="slide-container">
            <div className="divIMG"></div>
            <img className='imgSlider ' src={imagen} alt="Logo BlackSheep-But-White"/>
            <h1 className="slide-text-title">Crea tu página web con nosotros</h1>
            <p className="slide-text">Al trabajar con nosotros obtendrás una página web hecha con un estilo moderno el cual te dará profesionalidad ante los clientes.</p>
          </div>

          <div className="slide-container">
            <div className="divIMG"></div>
              <img className='imgSlider ' src={imagenDOS} alt="Logo BlackSheep-But-White"/>
              <h1 className="slide-text-title">Un diseño moderno y atractivo</h1>
              <p className="slide-text">Tu página contara con diseño responsivo que se adaptara a cualquier dispositivo </p>
            </div>

            <div className="slide-container">
            <div className="divIMG"></div>
              <img className='imgSlider ' src={imagenTRES} alt="Logo BlackSheep-But-White"/>
              <h1 className="slide-text-title">Dale una nueva imagen a tu negocio </h1>
              <p className="slide-text"> Una buena fuente para atraer a nuevos clientes interesados en tus productos o servicios. </p>
            </div>
            
        </Slider>
      </div>
    );
  }
}