import React from 'react';
import '../../styles/components/Clientes.css';
import '../../index.css';
import ApeActiveLogo from '../../images/logos/ApeActiveLogo.jpeg';
import ColpormalLogo from '../../images/logos/COLPORMALogo.jpeg';
import DonColorLogo from '../../images/logos/DonColorLogo.png';
import MineralesAlroLogo from '../../images/logos/MineralesAlroLogo.png';
import StrongDoorLogo from '../../images/logos/StrongdoorGarageLogo.png';
import ValleLogo from '../../images/logos/ValleVertLogo.jpeg';
import YustgLogo from '../../images/logos/Yustg-logo.PNG';






function Clientes() {
    function isElementInViewport(element) {
        var rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }
      
      function handleScroll() {
        var clienteDesElements = document.getElementsByClassName("cliente_des");
        var clienteImgElements = document.getElementsByClassName("cliente_imagen");
        for (var i = 0; i < clienteDesElements.length; i++) {
          var element = clienteDesElements[i];
          var elementImg = clienteImgElements[i];
          if (isElementInViewport(element)) {
            element.classList.add("visible");
            elementImg.classList.add("visible");
          } else {
            element.classList.remove("visible");
            elementImg.classList.remove("visible");
          }
        }
      }
      
      window.addEventListener("scroll", handleScroll);
      
      

    return (
        <>
            <div id='clientes' className='clientes_container'>
                <h1 className='clientes_titulo'>Clientes</h1>
                <div className='frase_container'>
                    <p className='frase'>Estamos orgullosos de haber colaborado con estas increibles compañias.</p>
                </div>
                <div className='clientes_grid_container'>
                    <div className='rows'>
                        <div className='cliente' >
                            <div className='cliente_imagen' >
                                <img className='imagen' src={ColpormalLogo} alt="ape" />
                            </div>
                            <div className='cliente_des'>
                                <p>Colporma: Diseñamos su logotipo y tarjetas de presentación, brindando una identidad visual sólida para su marca.</p>
                            </div>
                        </div>

                        <div className='cliente'>
                            <div className='cliente_imagen'>
                                <img className='imagen' src={StrongDoorLogo} alt="colpormal" />
                            </div>
                            <div className='cliente_des'>
                                <p>Strongdoor Garage: Desarrollamos su sitio web que incluye una landing page en inglés y español, así como un sistema de administración web.</p>
                            </div>
                        </div>

                        <div className='cliente'>
                            <div className='cliente_imagen'>
                                <img className='imagen' src={DonColorLogo} alt="doncolor" />
                            </div>
                            <div className='cliente_des'>
                                <p>Don Color: Apoyamos a Don Color en el desarrollo de su landing page, creando una presencia en línea atractiva y efectiva para promover sus productos y servicios.</p>
                            </div>
                        </div>
                        <div className='cliente'>
                            <div className='cliente_imagen'>
                                <img className='imagen' src={MineralesAlroLogo} alt="minerales" />
                            </div>
                            <div className='cliente_des'>
                                <p>Minerales Alro: Creamos una landing page que les permite presentar su catálogo de minerales y promocionar sus productos de manera efectiva.</p>
                            </div>
                        </div>

                        <div className='cliente'>
                            <div className='cliente_imagen'>
                                <img className='imagen' src={ApeActiveLogo} alt="strongdoor" />
                            </div>
                            <div className='cliente_des'>
                                <p>Ape Active: Colaboramos con Ape Active en el diseño de su logotipo, brindando una identidad visual única y memorable que representa su marca y sus valores.</p>
                            </div>
                        </div>

                        <div className='cliente'>
                            <div className='cliente_imagen'>
                                <img className='imagen' src={ValleLogo} alt="valle" />
                            </div>
                            <div className='cliente_des'>
                                <p>Valle Vert: Trabajamos en conjunto con Valle Vert en el diseño de su logotipo y su imagen de marca, ayudándoles a transmitir su estilo y personalidad a través de su identidad visual.</p>
                            </div>
                        </div>

                        <div className='cliente'>
                            <div className='cliente_imagen'>
                                <img className='imagen' src={YustgLogo} alt="yustg" />
                            </div>
                            <div className='cliente_des'>
                                <p>YUSTG: Realizamos el diseño completo de su sitio web, centrándonos en crear una experiencia de usuario atractiva y funcional para su audiencia.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}



export default Clientes

