import '../../styles/components/Nosotros.css';
import '../../index.css';
import React from 'react';
import Lottie from "lottie-react";
import animacionNosotros from '../../animations/aboutus.json';
import expertIcon from '../../images/expert-icon.png';
import confianzaIcon from '../../images/confianza.png';
import projectIcon from '../../images/project.png';
import satisfactionIcon from '../../images/satisfaction.png';


function Nosotros() {
    return (
        <>
            <div id='nosotros' className='nosotros_container'>
                <div className='header_container'>
                    <div className='header'>
                        <h1 className='titulo_header'>Acerca de Nosotros</h1>
                        <p className='parrafo_header'>
                            Somos un grupo de desarrolladores web experimentados que ofrecen un servicio completo de diseño y desarrollo de sitios web para empresas de todo tipo y tamaño. Nos enfocamos en crear sitios web atractivos y funcionales que brinden una excelente experiencia de usuario utilizando las últimas tecnologías y herramientas disponibles.
                        </p>
                    </div>
                    <div className='header_img_container'>
                        <Lottie
                            animationData={animacionNosotros}
                            loop={true}
                        />
                    </div>
                </div>
                <div className='column_layout'>
                    <div className='informacion_container'>
                        <div className='informacion'>

                            <div className='info_icon'>
                                <img className='icon' src={expertIcon} alt="expertIcon" />
                                <h1 className='titulo_informacion'>Expertos</h1>
                            </div>
                            <p className='parrafo_informacion'>Comprometidos con la excelencia y la calidad en cada proyecto.</p>
                        </div>
                        <div className='informacion'>
                            <div className='info_icon'>
                                <img className='icon' src={confianzaIcon} alt="confianzaIcon" />
                                <h1 className='titulo_informacion'>Confianza</h1>
                            </div>
                            <p className='parrafo_informacion'>Nos esforzamos por ganarnos la confianza de nuestros clientes en cada proyecto que abordamos.</p>
                        </div>
                        <div className='informacion'>
                            <div className='info_icon'>
                                <img className='icon' src={projectIcon} alt="projectIcon" />
                                <h1 className='titulo_informacion'>A la medida</h1>
                            </div>
                            <p className='parrafo_informacion'>Ofrecemos soluciones a la medida para cada proyecto.</p>
                        </div>
                        <div className='informacion'>
                            <div className='info_icon'>
                                <img className='icon' src={satisfactionIcon} alt="satisfactionIcon" />
                                <h1 className='titulo_informacion'>Satisfacción</h1>
                            </div>
                            <p className='parrafo_informacion'>La satisfacción del cliente es nuestra máxima prioridad en cada proyecto que abordamos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Nosotros