import React from 'react';
import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';
import Clientes from './components/common/Clientes';
import Header from './components/common/HeaderSlider';
import Nosotros from './components/common/Nosotros';
import Servicios from './components/common/Services';
import Reviews from './components/common/Reviews';
import Contacto from './components/common/Contacto';


function App() {
  return (

    <>
      
      <Navbar/>
      <Header/>
      <Nosotros/>
      <Servicios/>
      <Clientes/>
      {/* <Reviews/> */}
      {/* <Contacto/> */}
      <Footer/>
    </>
  );
}

export default App;
