import React from 'react';
import '../../styles/components/Navbar.css';
import logo from '../../images/logos/Black-Sheep-Outline.png';
import '../../index.css';
import Hamburger from 'hamburger-react';

function Navbar() {
    return (
        <>
            <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#inicio"><span><img src={logo} alt="logo blacksheep" /> </span> Black Sheep</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <Hamburger color='white' />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-container" >
                            <li className="nav-item">
                                <a className="nav-link hover-underline-animation " href="#inicio">Inicio</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link hover-underline-animation" href="#nosotros">Nosotros</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link hover-underline-animation" href="#servicios">Servicios</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link hover-underline-animation " href="#clientes">Clientes</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link hover-underline-animation" href="#footer">Contactanos</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar