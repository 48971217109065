// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore'
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDUnvLpnJGAxdADsz2OIbMbUN5TUsvlW9E",
  authDomain: "black-sheep-mx.firebaseapp.com",
  projectId: "black-sheep-mx",
  storageBucket: "black-sheep-mx.appspot.com",
  messagingSenderId: "197179095045",
  appId: "1:197179095045:web:f69fe00c55c99bdece846c",
  measurementId: "G-X9QHGNSMLC"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app)