import React from 'react';
import '../../styles/components/Footer.css';
import imagen from '../../images/logos/Black-Sheep-Outline.png';

function Footer() {
  return (
    
    <footer id='footer' className="footer">
      
      <div className="footer-content">
        <div className="footer-section about">
          
          
          <img className='imagen' src={imagen} alt="Logo BlackSheep"/>
          
        </div>
        <div className="footer-section links ">
          <h2 className="espLinks ">Sobre Nosotros</h2>
          <br />
          <br />
          <ul>
            <li><a className="espLinks" href="#">Inicio</a></li>
            <li><a className="espLinks" href="#">Servicios</a></li>
            <li><a className="espLinks" href="#">Nosotros</a></li>
            
            <li><a className="espLinks" href="#">Política de privacidad</a></li>
            <li><a className="espLinks" href="#">Términos y condiciones</a></li>
          </ul>
        </div>
        <div className="footer-section contact espdos">
          <h2 className='espTitulo'>Contacto</h2>
          <ul>
            <li><a className='bi bi-envelope contenedor-icono'></a> <i> contacto@black-sheep.com.mx</i></li>
            <li><a className='bi bi-telephone-fill contenedor-icono'></a><i> 7223664215 </i></li>
          </ul>
          
        </div>
      </div>

      
      <div className="footer-bottom"> 
        <div className="footer-bottom-leftA"> <i>Nuestras redes</i></div>
       <div className="footer-bottom-left"> 
         
         <i  ><a className="bi bi-facebook contenedor-icono" href="https://www.facebook.com/"></a></i> 
         <i  ><a className="bi bi-whatsapp contenedor-icono" href="https://www.twitter.com/"></a></i> 
         <i  ><a className="bi bi-instagram contenedor-icono" href="https://www.instagram.com/"></a></i> 
         <i  ><a className="bi bi-linkedin contenedor-icono" href="https://www.linkedin.com/"></a></i> 
         
       </div>
        <div className='footer-bottom-right'>
          <p><i>© 2023 Black-Sheep. </i> <i className='desaparecer'>Todos los derechos reservados.</i></p>
        </div>
      </div>
    
    </footer>
  );
}

export default Footer;
